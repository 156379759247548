import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import { detectCountry } from "../../hoc/Shared/utility";

// language file
import translationEN from './en/translation.json';
import translationZHCN from './cn/translation.json';
import translationMS from './ms/translation.json';
import translationVN from './vn/translation.json';
import gamesEN from './en/games.json';
import gamesZHCN from './cn/games.json';
import gamesMS from './ms/games.json';
import gamesVN from './vn/games.json';
import routesEN from './en/routes.json';
import routesZHCN from './cn/routes.json';
import routesMS from './ms/routes.json';
import routesVN from './vn/routes.json';

import translationIndon from './indon/translation.json';
import gamesIndon from './indon/games.json';
import routesIndon from './indon/routes.json';

let resources = {};

resources = {
  en: {
    translation: translationEN,
    games: gamesEN,
    routes: routesEN
  },
  zh_cn: {
    translation: translationZHCN,
    games: gamesZHCN,
    routes: routesZHCN
  },
  ms: {
    translation: translationMS,
    games: gamesMS,
    routes: routesMS
  },
  id: {
    translation: translationIndon,
    games: gamesIndon,
    routes: routesIndon
  },
  vn: {
    translation: translationVN,
    games: gamesVN,
    routes: routesVN
  }
}

let dynamicCountry = detectCountry(window.location.host);
let initLang = localStorage.getItem("lang") ? localStorage.getItem("lang") : (dynamicCountry?.data[2] || "en");

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: initLang, //localStorage.getItem('lang') || "en",
    fallbackLng: "en",
    // keySeparator: false, // if do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      wait: false
    }
  });

export default i18n;